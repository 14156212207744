html[data-theme="dark"]
  .home
    background: linear-gradient(30deg, #18191A, #13161C)
  .buttonWithLogo img
    filter: invert(1)

.home
  padding-top: 3rem
  display: flex
  align-items: top
  justify-content: space-around
  gap: 2rem
  color: var(--text-color)
  background: linear-gradient(30deg, #fff, #f0f0f0)
  height: calc(100vh - 3rem)
  overflow-y: auto
  margin-top: 3rem
  h1
    margin-bottom: 2rem
  p
    font-size: 1rem
    line-height: 1.66
    margin-bottom: 1.5rem
  em
    font-style: normal
    font-weight: 600
  .creator
    height: 10rem
    width: 10rem
    img
      width: 100%
      height: 100%
      object-fit: cover
      border-radius: 50%

  ul
    margin: 0 0 2rem
    font-size: 1rem
    list-style: disc
    padding-left: 1rem
    li
      margin-bottom: 1rem

  .buttons
    display: flex
    flex-direction: column
    gap: .5rem

  .creatorProfile
    margin-top: 3rem
    display: flex
    align-items: center
    gap: 1rem
    p
      font-size: .875rem
      margin: 0
    img
      width: 5rem
      height: 5rem
      border-radius: 50%


  .quickLinks
    display: flex
    width: 100%
    gap: 6rem
    flex-wrap: wrap
    justify-content: center

    .module
      width: calc(33.33% - 3rem)
      min-width: 320px
      flex: 0 0 auto
      padding-bottom: 2rem
      &.keyTopics
        width: calc(50%)
      h3
        text-align: center

    .bubblesContainer
      display: flex
      flex-direction: column
      gap: 1rem

    .bubble
      display: flex
      justify-content: space-between
      align-items: center
      background: var(--canvas-bubble-bg-color)
      border: 1px solid var(--canvas-bubble-border-color)
      box-shadow: var(--box-shadow-small)
      border-radius: var(--border-radius-small)
      padding: 1rem
      transition: all .1s ease-in-out
      cursor: pointer
      &:hover
        border-color: var(--border-color-hover)

    .bubbleHeader
      font-size: 1rem
      font-weight: 600

    .bubbleUpdatedAt
      color: #888
      font-size: 0.875rem
      white-space: nowrap
      
    .topics
      position: relative
    .topic
      --topic-color: hsla(220, 80%, 50%, .5)
      --topic-color-2: hsla(195, 80%, 50%, .5)

      --overlay-color: white
      --overlay-color-2: rgba(255, 255, 255, .5)
      background: linear-gradient(45deg, var(--topic-color), var(--topic-color-2))
      border: 1px solid var(--border-color)
      height: 10rem
      width: 10rem
      border-radius: 50%
      position: absolute
      top: 0
      left: 0
      display: flex
      align-items: center
      justify-content: center
      user-select: none
      cursor: pointer
      transition: transform .1s ease-in-out
      font-weight: 500
      padding: 2rem
      &:hover
        transform: scale(1.02)
        border-color: var(--border-color-hover)
      &:active
        transform: scale(1)
      .topicName
        position: relative
        z-index: 1
        text-align: center
      &:nth-child(2)
        --topic-color: hsla(120, 80%, 50%, .5)
        --topic-color-2: hsla(72, 80%, 50%, .5)
        top: 10rem
        left: 10rem
        height: 15rem
        width: 15rem
      &:nth-child(3)
        --topic-color: hsla(270, 80%, 50%, .5)
        --topic-color-2: hsla(78, 80%, 50%, .5)
        top: 20rem
        left: 20rem
        height: 20rem
        width: 20rem
      &:nth-child(4)
        --topic-color: hsla(3, 80%, 50%, .5)
        --topic-color-2: hsla(72, 80%, 50%, .5)
        top: 5rem
        left: 20rem
        height: 12rem
        width: 12rem

  .welcome
    padding: 0 3rem
    width: 100vw
    flex: 0 0 auto
    display: flex
    justify-content: center
    position: relative
    transition: all .3s ease-in-out
    .content
      width: 600px
      flex: 0 0 auto
    .closeButton
      position: absolute
      top: .5rem
      right: 1rem
    &.hidden
      opacity: 0
      width: 0
      padding: 0
      overflow: hidden
    .buttons
      flex-direction: row
  .info
    text-align: center

.randomBubble
  position: absolute
  top: 4rem
  right: 1rem
