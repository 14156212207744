
.bubbleLinkCont
	display: inline
	border: 1px solid var(--canvas-bubble-border-color)
	padding: 4px
	border-radius: var(--border-radius-small)
	margin: 0px 2px
	cursor: pointer
	pointer-events: auto !important

.toolbarContainer
	position: absolute
	top: 2rem
	opacity: 0
	z-index: 1000
	visibility: collapse
	transition: 250ms all
	text-decoration: none
	pointer-events: auto !important
	&.visible
		opacity: 1
		visibility: visible

.content
	text-decoration: underline
	position: relative

.search
	display: flex
	width: 300px
	align-items: center
	.searchResults
		position: absolute
		top: 5rem
		right: 0
		width: 100%
		background-color: var(--bn-colors-menu-background)
		border: var(--bn-border)
		border-radius: var(--bn-border-radius-medium)
		box-shadow: var(--bn-shadow-medium)
		color: var(--text-color)
		padding: .5rem
		display: flex
		flex-direction: column
		gap: .5rem
		max-height: 20rem
		overflow-y: auto
		&.empty
			display: none
		.searchResult
			padding: .5rem
			border-radius: var(--border-radius-small)
			cursor: pointer
			transition: .1s all
			display: flex
			align-content: center
			gap: .5rem
			&:hover
				background: var(--button-hover-color)
