.loginScreen
  height: 100vh
  width: 100vw
  background: #fff
  display: flex
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif
  @media (max-width: 680px)
    display: block
    
  

.background
  width: 50%
  height: 100%
  overflow: hidden
  flex: 0 0 auto
  @media (max-width: 680px)
    width: 100%

.login
  display: flex
  align-items: center
  justify-content: center
  padding: 0 5rem
  @media (max-width: 680px)
    position: absolute
    left: 0
    top: 50%
    background-color: #fff
    transform: translateY(-50%)
    text-align: center
    padding: 4rem 2rem

h1
  font-size: 2rem
  margin-bottom: .67rem
  font-weight: bold

h3
  font-size: 1.17rem
  margin-bottom: 3rem
  font-weight: 500

h4
  font-size: 1rem
  margin: 4rem 0 .5rem
  font-weight: 500

input
  padding: .5rem
  border: 1px solid #ddd
  border-radius: var(--border-radius-small)
  font-family: var(--font-family)
  width: 100%

.registrationContainer
  display: flex
  gap: .5rem

.errorContainer
  border: var(--border-color) 1px solid
  border-radius: var(--border-radius-small)
  padding: .5rem
  background: var(--error-color)
  color: var(--error-text-color)
  display: flex
  align-items: center
  gap: .5rem
  margin: .5rem 0
  max-width: 420px
  svg
    vertical-align: middle
    transform: translateY(-2px)
  a
    color: var(--error-text-color)
    text-decoration: underline

@media (min-width: 768px)
  .button
    padding: 10px 30px

@property --color1
  syntax: '<color>'
  inherits: false
  initial-value: #72bbff

  /* Initial gradient color

@property --color2
  syntax: '<color>'
  inherits: false
  initial-value: #ffffff

  /* Initial gradient color

@property --gradient-angle
  syntax: '<angle>'
  inherits: false
  initial-value: 45deg

  /* Initial gradient angle

.design
  --color1:#ffffff
  --color2:#ffffff
  --gradient-angle: 45deg
  height: 100%
  position: relative
  // transition: --color1 .4s, --color2 .4s
  background: linear-gradient(var(--gradient-angle), var(--color1), var(--color2))

  .circle
    width: 300px

    /* variable
    height: 300px

    /* variable
    background: radial-gradient(transparent, rgba(255, 255, 255, 0.2))
    border: 1px solid rgba(255, 255, 255, 0.5)

    /* background: radial-gradient(transparent, rgba(0, 0, 0, 0.2));
     *border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%
    position: absolute
    top: 120px

    /* variable
    left: -10px

    /* variable
    transition: .4s all
    mix-blend-mode: normal

    &:nth-child(2)
      top: 350px

      /* variable
      left: 150px

      /* variable
      width: 300px

      /* variable
      height: 300px

      /* variable
