:global
	:root
		--button-border-color: rgba(0, 0, 0, .3)
		--button-hover-border-color: rgba(0, 0, 0, .5)
		--button-bg-color: rgba(255, 255, 255, 1)

	.buttons
		display: flex
		gap: .5rem

	.button
		border: 1px solid var(--button-border-color)
		border-radius: var(--border-radius-small)
		padding: .5rem .75rem
		line-height: 1
		cursor: pointer
		background: var(--button-bg-color)
		color: var(--text-color)
		font-weight: 500
		transition: .1s all
		box-shadow: var(--box-shadow-small)
		display: inline-flex
		align-items: center
		gap: .5rem
		flex: 0 0 auto
		user-select: none
		&:hover
			border-color: var(--button-hover-border-color)
			box-shadow: var(--box-shadow-small-hover)
			transform: scale(1.03)
		&.disabled
			opacity: 0.5
			cursor: default
			&:hover
				border-color: var(--button-border-color)
				box-shadow: var(--box-shadow-small)
				transform: initial


		&.size-small
			padding: .33rem .5rem
			font-size: .75rem
			gap: .25rem

		&.primary
			background: radial-gradient(circle at 100% 100%, transparent 7px, #50d365 7px, #50d365 8px, transparent 8px), linear-gradient(to right, #50d365, #304ffe), radial-gradient(circle at 0% 100%, transparent 7px, #304ffe 7px, #304ffe 8px, transparent 8px), linear-gradient(to bottom, #304ffe, #00bfa5), radial-gradient(circle at 0% 0%, transparent 7px, #00bfa5 7px, #00bfa5 8px, transparent 8px), linear-gradient(to left, #00bfa5, #33bbff), radial-gradient(circle at 100% 0%, transparent 7px, #33bbff 7px, #33bbff 8px, transparent 8px), linear-gradient(to top, #33bbff, #50d365)
			background-size: 8px 8px, calc(100% - 16px) 1px, 8px 8px, 1px calc(100% - 16px)
			background-position: top left,top center,top right,center right, bottom right,bottom center,bottom left,center left
			background-repeat: no-repeat
			border: 0
			padding: 9px .80rem

		&:active
			transform: scale(.98)
			box-shadow: none
			transition: .07s all

		.buttonIcon
			display: flex
			align-items: center
			justify-content: center
			svg, img
				width: 1rem
				height: 1rem
		&.size-small
			.buttonIcon
				width: .75rem
				height: .75rem
		
		.content
			display: flex
			justify-content: space-between
			gap: .5rem
			width: 100%
		.price
			opacity: .5

		&.type-toggle
			&.active
				transform: scale(.98)
				transition: .07s all
				box-shadow: none
				filter: invert(1)