.mainView
  height: calc(100vh)
  padding-top: 3rem
  background: linear-gradient(30deg, #fff, #ebebeb)
  display: flex
  width: 100%
  .wrapper
    position: relative
    display: flex
    flex: 1 1 auto
    flex-direction: column
    width: 100%
    height: calc(100% - 1rem)
    margin: .5rem
    border-radius: var(--border-radius-medium)
    border: 1px solid var(--border-color)
    overflow: hidden
    background: linear-gradient(196deg, var(--canvas-bg-color-1), var(--canvas-bg-color-2))
    .contentWrapper
      display: flex
      height: 100%
    &.target
      outline: 2px dashed #3273dc
      filter: brightness(0.9)
    &.unsupportedFile
      background: #fef6f6
      color: #ff6b6b
      transition: all 0.3s ease
      position: relative
      overflow: hidden

      &::before
        content: "⚠️ File type not supported"
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        font-size: 0.9rem
        font-weight: 500
        color: #ff6b6b
        pointer-events: none
    &.closed
      @keyframes closeBubble
        0%
          opacity: 1
          transform: scale(1)
        100%
          opacity: 0
          transform: scale(.6)
          display: none
          
      animation: closeBubble .25s forwards ease-out
    &.minimized
      @keyframes minimizeBubble
        0%
          height: 100%
        100%
          height: 2.75rem
      animation: minimizeBubble .25s forwards ease-out
      position: absolute
      bottom: 0
      top: auto

html[data-theme="dark"]
  .mainView
    background: linear-gradient(30deg, #18191A, #13161C)

.headerName
  line-height: 2rem
  background: var(--background-color)
  font-size: 1.2rem
  padding: .4rem 1.15rem
  width: 100%
  border-bottom: 1px solid var(--border-color)
  transition: .1s background, .1s width
  color: var(--text-color)
  display: flex
  flex-direction: column
  z-index: 1
  .path
    font-size: .875rem
    display: flex
    align-items: center
    line-height: 1
  .breadcrumbTitle
    background: rgba(0, 0, 0, .04)
    padding: .125rem .25rem
    border-radius: .25rem
    &:hover
      background: rgba(0, 0, 0, .1)
      cursor: pointer
  .breadcrumbDropdownMenu
    position: absolute
    background: var(--background-color)
    border-radius: .25rem
    border: 1px solid var(--border-color)
    z-index: 10000
  .breadcrumbDropdownMenuItem
    padding: .5rem .75rem
    border-bottom: 1px solid var(--border-color)
    cursor: pointer
    &:last-child
      border-bottom: 0
    &:hover
      background: rgba(0, 0, 0, .05)
  .breadcrumbSeparator
    font-size: .5rem
    opacity: .5
    margin: 0 .25rem
    cursor: default
  
// Testing things

.guide
  height: 60%
  width: 100%
  position: absolute
  display: flex
  align-items: center
  justify-content: center
  color: var(--text-color)
  opacity: .5

.noTextHighlight
    -webkit-touch-callout: none
    -webkit-user-select: none
     -khtml-user-select: none
       -moz-user-select: none
        -ms-user-select: none 
            user-select: none

.bubbleControls
  position: absolute
  height: 2.75rem
  display: flex
  right: .875rem
  align-items: center
  gap: .25rem
  opacity: .75
  z-index: 2
  .stepOut, .close
    text-align: center
    vertical-align: middle
    z-index: 2
    cursor: pointer
    border: 1px solid rgba(0,0,0,.1)
    border-radius: .5rem
    padding: .125rem .25rem
    &:hover
      opacity: 1
  .close
    // background, beautiful and subtle red
    background: #ff5a5a00
    &:hover
      background: #ff5a5a33
  .stepOut
    // background, beautiful and subtle yellow
    background: #ffdd5a00
    &:hover
      background: #ffdd5a33
    


/* Hide .bubbleControls in .viewMode-document on small screens */
@media (max-width: 800px)
  .bubbleControls
    display: none