@keyframes border-dance
	0%
		background-position: left top, right bottom, left bottom, right top
	100%
		background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px

@keyframes newBubble
	0%
		transform: scale(0)
		opacity: 0
	100%
		transform: scale(1)
		opacity: 1

.canvasBubble
	background: var(--canvas-bubble-bg-color)
	border-radius: 1rem
	border: 1px solid var(--canvas-bubble-border-color)
	box-shadow: var(--box-shadow-small)
	outline: 0
	position: absolute
	width: 300px
	min-height: 50px
	max-height: 100% 
	padding-bottom: 0
	overflow: hidden
	transition: background-color .1s
	display: flex
	flex-direction: column
	&:hover
		.buttons
			opacity: 1

	&.focused
		z-index: 1

	&.hidden
		display: none

	.buttons
		flex: 0 0 auto
		align-self: flex-start
		opacity: .5
		transition: opacity .1s
		gap: .125rem
		transform: translate(0, -2px)

	&.highlighted
		border-color: var(--text-color)
		transform: scale(1.01)

	// attached means a child bubble within the child bubble container, not free in the canvas
	&.attached
		position: relative
		//min-width: 100% !important

	&.imageContent
		img
			width: 100%
			height: auto

	&:global
		&:has(.command)
			background-color: #fffe64
			// box-shadow: none
			// transform: translate(-2px, -2px)

	.header
		padding: .4rem .5rem .2rem 1.25rem
		border-bottom: 1px solid rgba(0,0,0,.08)
		cursor: grab
		font-weight: 400
		user-select: none
		display: flex

		input
			background: none
			border: none

	.resizeToggle
		width: 1.5rem
		height: 1.5rem
		position: absolute
		bottom: 0
		right: 0
		opacity: .2
		cursor: nwse-resize
		&:hover, &:focus, &:active
			opacity: .8

		.icon
			box-sizing: border-box
			display: inline-block
			font-size: inherit
			font-style: normal
			position: relative
			text-indent: -9999px
			vertical-align: middle
			width: 20px
			height: 20px
			line-height: 100%

		.icon::before,
		.icon::after
			content: ""
			display: block
			left: 50%
			position: absolute
			top: 50%
			transform: translate(-50%, -50%)

		.iconLink::before
			border: .1rem solid var(--text-color)
			border-right: 0
			border-top: 0
			border-left: 0
			height: 1.4em
			transform: translate(-84%, -84%) rotate(-45deg)
			width: 1.4em

		.iconLink::after
			border: .1rem solid var(--text-color)
			border-right: 0
			border-top: 0
			border-left: 0
			height: .7em
			transform: translate(-50%, -50%) rotate(-45deg)
			width: .7em

	.wrapper
		height: 100%
		max-height: calc(100% - 40px)
		display: flex
		flex-direction: column
		overflow: hidden
		flex: 1

		.document
			padding: 0px !important
			margin: 0 -.5rem 0 -.25rem
			overflow: auto
			position: relative
			max-height: 80%
			height: 100%
			flex: 1
			flex-basis: 0
			min-height: 63px
			overflow: auto

		.childBubbles
			position: relative
			flex-basis: 0
			overflow: auto
			min-height: 40px
			padding: 0px
			&.expanded
				flex: 1
				flex-basis: 0
				overflow: auto
				min-height: 160px
			&.collapsed
				flex: 1
				flex-basis: 0
				max-height: 55px
				overflow: auto
				padding: none !important

	.childBubbleContainer
		display: flex
		flex-wrap: wrap
		padding: 1rem
		gap: .5rem
		border-top: 1px solid rgba(0,0,0,.1)
		overflow-y: auto // Enable vertical scroll specifically

	.childBubbleToggle
		border: 1px solid rgba(0,0,0,.1)
		padding: .125rem .25rem
		font-size: .75rem
		opacity: .75
		border-radius: .5rem
		cursor: pointer
		display: inline-block
		margin: .25rem 1rem
		&.expanded
			border-color: black

.spinner
	animation: spin 1s infinite
	color: var(--text-color)
	font-size: 50px
	line-height: 250px
	height: 250px
	margin: auto
	
@keyframes spin 
	100% 
		transform: rotate(360deg)

.pdfEmbed
	width: 100%
	height: 100%

.dragPreview
	pointer-events: none
	background: rgba(255, 255, 255, 0.9)
	border: 2px dashed #666
	z-index: 1000

.isDragging
	

.draggingPlaceholder
	height: 100%
	background: rgba(0, 0, 0, 0.1) 

.pdfContainer
	max-height: 100% 
	overflow-y: auto
	overflow-x: hidden 
	width: 100%
	flex: 1 

.pdfPage
	margin-bottom: 10px 
	border: 1px solid #ddd 
	width: 100% 
	height: auto 

.pdfWrapper
	width: 100%
	height: 100%