.loader
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: 0.5rem
  background: var(--background-color)
  padding: 1rem
  border-radius: var(--border-radius-medium)
  box-shadow: var(--box-shadow)
  z-index: 1000
  color: var(--text-color)
  font-family: var(--font-family)
  text-align: center
  width: 280px

.spinner
  width: 30px
  height: 30px
  border: 3px solid rgba(255, 255, 255, 0.3)
  border-top: 3px solid var(--text-color)
  border-radius: 50%
  animation: spin 1s linear infinite

@keyframes spin
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

.progressContainer
  position: relative
  width: 100%
  height: .5rem
  background-color: var(--canvas-bubble-border-color)
  border-radius: var(--border-radius-small)
  overflow: hidden

.progressBar
  position: absolute
  height: 100%
  background: linear-gradient(90deg, var(--text-color), var(--text-color))
  transition: width 0.3s ease-in-out

.progressText
  font-size: 0.8rem
  margin-top: 0.3rem
