.scratchpad
    flex: 0 0 25%
    border-radius: var(--border-radius-medium)
    border: 1px solid var(--border-color)
    margin: .5rem 0 .5rem .5rem
    background: var(--background-color)
    min-height: calc(100% - 1rem)
    margin-bottom: 6rem
    margin-top: 3rem
    z-index: 1
    min-width: 20rem
    @media (max-width: 485px)
        margin: 3rem 0 0
        height: calc(100% - 3rem)
        min-height: auto
        border-radius: 0
        border: 0
        border-top: 1px solid var(--border-color)
    .scratchpadContent
        height: 100%
        padding: 1rem .125rem


.mobileFormattingBar
    position: absolute
    top: 3.4rem
    padding: 0 .5rem
    left: 0
    width: 100%
    div
        flex-wrap: wrap!important
        width: 100%!important