.mobileContainer
  display: flex
  flex-direction: column
  min-height: 100vh 
  width: 100%
  position: relative

.headerWrapper
  flex: 0 0 auto 
  position: sticky
  top: 0
  z-index: 10 

.scratchpadWrapper
  flex: 1 1 auto 
  margin-top: 3rem 
  width: 100%
  height: calc(100vh - 3rem) 
  overflow-y: auto 