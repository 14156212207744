.dropdown
    border-radius: var(--border-radius-small)
    border: 1px solid var(--border-color)
    padding: .125rem .5rem
    display: flex
    flex-direction: column
    gap: .125rem
    width: 100%
    position: relative
    .dropdownTrigger
        cursor: pointer
        display: flex
        justify-content: space-between
        align-items: center
        gap: .125rem
        &.disabled
            opacity: .5
            pointer-events: none
    .dropdownContent
        position: absolute
        top: -.125rem
        transform: translateY(-100%)
        left: 0
        flex-direction: column
        gap: .125rem
        width: 150%
        background: var(--background-color)
        border-radius: var(--border-radius-small)
        border: 1px solid var(--border-color)
        overflow: hidden
        .dropdownOption
            cursor: pointer
            padding: .5rem .5rem
            border-bottom: 1px solid var(--list-border-color)
            &:hover
                background: var(--list-hover-color)
            &:last-child
                border-bottom: none
        