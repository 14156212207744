.canvas
  height: 100%
  width: 100%
  position: relative
  overflow: auto

  &.documentViewMode
    display: none

  &:hover .noChildren
    opacity: 1
#selection
  position: absolute
  z-index: 1000
  border-radius: 2px
  pointer-events: none
  border: 1px solid rgba(74, 169, 233, 0.741)
  background: rgba(74, 169, 233, 0.05)


@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

.viewModes
  position: absolute
  opacity: 0
  top: .5rem
  left: 0
  animation: fadeIn .1s forwards
  animation-delay: .3s
  z-index: 1000
  :global(.button)
    border-top-left-radius: 0
    border-bottom-left-radius: 0

.dragPreview
  position: relative

  .test
    font-size: 5rem


.noChildren
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: 360px
  margin: 25vh auto 0
  text-align: center
  opacity: .7
  transition: opacity .25s ease-in-out
  .noChildrenImage
    width: 280px
    height: 140px
    border-radius: 2rem
    margin-bottom: 1.5rem
    border: 3px dashed var(--text-color)
    display: flex
    align-items: center
    justify-content: center
    opacity: .25
    svg
      color: var(--text-color)
  p
    margin-bottom: 1rem
    opacity: .66
    color: var(--text-color)
    user-select: none