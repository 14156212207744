header
  height: 3rem
  top: 0
  width: 100%
  background: #fff
  position: absolute
  border-bottom: 1px solid var(--border-color)
  padding: 0 .5rem
  display: flex
  align-items: center
  justify-content: space-between
  z-index: 3
  gap: .25rem

html[data-theme="dark"]
  header
    background: linear-gradient(to top, rgba(255, 255, 255, .05), rgba(255, 255, 255, .0))

header .staticMenu
  display: flex
  align-items: center
  gap: .5rem

@property --logo-color-1
  syntax: '<color>'
  initial-value: #222
  inherits: false
@property --logo-color-2
  syntax: '<color>'
  initial-value: #444
  inherits: false

@keyframes logo-hover
  0%
    --logo-color-1: #222
    --logo-color-2: #444
  25%
    --logo-color-1: #6067dd
    --logo-color-2: #282828
  50%
    --logo-color-1: rgba(60,67,222,1)
    --logo-color-2: #00ffd0
  75%
    --logo-color-1: #00eaff
    --logo-color-2: rgba(81,222,60,1)
  100%
    --logo-color-1: rgba(209,255,0,1)
    --logo-color-2: rgba(81,222,60,1)

.logo
  padding: 0 .75rem 0 .5rem
  border: 1px solid var(--border-color)
  border-radius: var(--border-radius-small)
  background: var(--background-color)
  cursor: pointer
  background: radial-gradient(circle, #222, #444)
  color: white
  transition: all .1s ease-out
  transform: scale(.8)
  border-radius: 3rem
  height: 2.75rem
  width: 2.75rem
  display: flex
  align-items: center
  justify-content: center
  padding: 0
  flex: 0 0 auto
  &:hover
    transform: scale(1)
    border: 0
    background: radial-gradient(circle, var(--logo-color-1) 0%, var(--logo-color-2) 100%)
    animation: logo-hover .15s ease-in forwards
    box-shadow: var(--box-shadow-small)
    img
      height: 2rem
  &:active
    transform: scale(.9)
    box-shadow: none
    transition: .07s all
  img
    height: 1.5rem
    filter: invert(1)
    margin: 0
    transition: height .15s ease-out

.historyNav
  display: flex
  align-items: center
  gap: .5rem
  button
    display: flex
    align-items: center
    gap: .25rem
    padding: .5rem
    border: 1px solid var(--border-color)
    border-radius: var(--border-radius-small)
    background: var(--background-color)
    cursor: pointer
    transition: .1s all
    &:hover
      background: var(--button-hover-color)
    &:disabled
      cursor: not-allowed
      opacity: .5
    svg
      vertical-align: middle

/* dark theme */
html[data-theme="dark"]
  header button, header .button
    img
      filter: invert(1)
      mix-blend-mode: lighten
  .search
    svg
      filter: invert(1)

header .profileName .name
  height: 2rem
  width: 2rem
  background: linear-gradient(30deg, #222, #444)
  border-radius: 50%
  color: white
  text-align: center
  line-height: 2rem
  font-weight: 400
  cursor: pointer
  flex: 0 0 auto
  display: block
  transition: .1s all
  user-select: none
  &:hover
    transform: scale(1.15)
    box-shadow: var(--box-shadow-small)
  &:active
    transform: scale(1.08)
    box-shadow: none
    transition: .07s all

.search
  position: relative
  min-width: 10rem
  max-width: 36rem
  flex-grow: 1
  svg
    position: absolute
    left: .5rem
    top: 50%
    transform: translateY(-50%)
    opacity: .5
  input
    padding: .5rem 1rem .5rem 2rem
    border: 1px solid var(--border-color)
    border-radius: var(--border-radius-small)
    background: var(--background-color)
    color: var(--text-color)
    width: 100%
    transition: .1s all
    &:hover, &:focus
      border-color: var(--hover-border-color)
  .searchResults
    position: absolute
    top: 3rem
    right: 0
    width: 100%
    background: var(--background-color)
    border: 1px solid var(--border-color)
    border-radius: var(--border-radius-small)
    color: var(--text-color)
    padding: .5rem
    display: flex
    flex-direction: column
    gap: .5rem
    max-height: 20rem
    overflow-y: auto
    box-shadow: var(--box-shadow)
    &.empty
      display: none
    .searchResult
      padding: .5rem
      border-radius: var(--border-radius-small)
      cursor: grab
      transition: .1s all
      display: flex
      align-content: center
      gap: .5rem
      &:hover
        background: var(--button-hover-color)

.profileSettings
  width: 240px
  right: .5rem
  top: 2.75rem
  position: absolute
  text-align: left
  background: var(--background-color)
  box-shadow: var(--box-shadow)
  border-radius: var(--border-radius-small)
  color: var(--text-color)
  border: 1px solid var(--border-color)
  list-style: none
  overflow: hidden
  z-index: 1001
  li
    padding: .5rem .5rem
    border-bottom: 1px solid rgba(0, 0, 0, .1)
    display: flex
    align-items: center
    gap: .5rem
    cursor: pointer
    &:hover
      background: var(--button-hover-color)
    &:last-child
      border-bottom: none

.searchAndProfile
  display: flex
  gap: 1rem
  align-items: center

  .sign
    cursor: pointer


.toggle
  display: flex
  align-items: center
  gap: .5rem
  color: var(--text-color)
  .toggleButton
    border: 1px solid var(--border-color)
    width: 2.5rem
    height: 1.5rem
    border-radius: 1rem
    background: rgba(0, 0, 0, .15)
    box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, .15)
    position: relative
    cursor: pointer
    &::before
      font-size: .75rem
      content: '☀️'
      display: block
      width: 1.125rem
      height: 1.125rem
      line-height: 1.125rem
      text-align: center
      border-radius: 1rem
      background: var(--background-color)
      border: 1px solid var(--border-color)
      position: absolute
      left: .1rem
      top: 50%
      transform: translateY(-50%)
      transition: .1s all
    &.option1
      &::before
        left: 1.1rem
        content: '🌙'
    &.option2
      &::before
        content: '☀️'

/* Hide .dynamicMenu on small screens */
@media (max-width: 800px)
  header
    .search input
      width: 200px
    .searchAndProfile
      gap: 0
      button
        display: none